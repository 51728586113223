import $ from 'jquery';
import 'magnific-popup';

$(window).on('load', () => {
    const $popupLogin = $('.js-popup-login');
    const $sliderPopup = $('.js-slider-popup');

    if ($popupLogin.length) {
        const mfpOptions = {
            type: 'inline',
            fixedContentPos: true,
            closeOnBgClick: true,
            mainClass: 'mfp-login',
            callbacks: {
                open: function() {
                    $('.js-popup-close').on('click', function(event) {
                        event.preventDefault();
                        $.magnificPopup.close();
                    })
                }
            }
        };

        $(document).on('click', '.js-popup-login', function () {
            if (!$(this).data('magnificPopup')) {
                $(this).magnificPopup(mfpOptions).magnificPopup('open');
            }
        });

        $popupLogin.magnificPopup(mfpOptions);

        const $btnCreate = $('.js-btn-create');
        const $btnLogin = $('.js-btn-login');
        const $btnForgot = $('.js-btn-forgot');
        const $formRegister = $('.js-form-register');
        const $formLogin = $('.js-form-login');
        const $formForgot = $('.js-form-forgot');
        const $titleSignin = $('.js-signin-title');
        const $titleSignup = $('.js-signup-title');
        const $titleForgot = $('.js-forgot-title');

        $btnCreate.on('click', (e) => {
            e.preventDefault();

            $formRegister.removeClass('hidden');
            $formLogin.addClass('hidden');
            $formForgot.addClass('hidden');

            $btnLogin.removeClass('hidden');
            $btnCreate.addClass('hidden');

            $titleSignup.removeClass('hidden');
            $titleSignin.addClass('hidden');
            $titleForgot.addClass('hidden');
        });

        $btnLogin.on('click', (e) => {
            e.preventDefault();

            $formRegister.addClass('hidden');
            $formLogin.removeClass('hidden');
            $formForgot.addClass('hidden');

            $btnLogin.addClass('hidden');
            $btnCreate.removeClass('hidden');

            $titleSignin.removeClass('hidden');
            $titleSignup.addClass('hidden');
            $titleForgot.addClass('hidden');
        });

        $btnForgot.on('click', (e) => {
            e.preventDefault();

            $formForgot.removeClass('hidden');
            $formRegister.addClass('hidden');
            $formLogin.addClass('hidden');

            $btnLogin.removeClass('hidden');
            $btnCreate.removeClass('hidden');

            $titleForgot.removeClass('hidden');
            $titleSignup.addClass('hidden');
            $titleSignin.addClass('hidden');
        });
    }

    if ($sliderPopup.length) {
        $sliderPopup.magnificPopup({
            type: 'image',
            gallery: {
                enabled: true,
            },
            fixedContentPos: true,
            showCloseBtn: true,
        });
    }
});
